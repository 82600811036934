@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.cdnfonts.com/css/general-sans');

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
  }
}

body {
  margin: 0;
  font-family: 'General Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  ul {
    @apply list-inside list-disc
  }

  ol {
    @apply list-inside list-decimal
  }
}

.product-container {
  @apply grid grid-cols-2 items-start gap-3 w-full lg:grid-cols-4 lg:gap-4 xl:grid-cols-6 xl:gap-5
}

.button-primary {
  @apply text-sm bg-black-multi w-full py-2.5 text-white-multi rounded-md transition ease-in-out duration-200 border border-black-multi
}

.button-primary.dark {
  @apply text-sm bg-yellow-multi w-full py-2.5 text-black-multi rounded-md transition ease-in-out duration-200 border border-yellow-multi
}

.button-primary.disabled {
  @apply bg-gray-multi text-gray-300
}

.button-secondary {
  @apply text-sm bg-white-multi border border-black-multi w-full py-2.5 text-black-multi rounded-md
}

.button-secondary.disabled {
  @apply text-gray-600 brightness-95
}

.input-primary {
  @apply p-3 border border-black-multi w-full bg-white-multi text-sm outline-none
}

.input-primary.disabled {
  @apply text-gray-600 brightness-95
}

.react-calendar__tile--active {
  @apply bg-black-multi
}

.swiper-button-next svg,
.swiper-button-prev svg {
  @apply text-gray-multi;
  width: 30px;
  height: 30px;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  content: "";
}

.banner-pagination {
  margin-top: 15px;
  width: 75% !important;
  @apply mx-auto flex justify-center items-center;
}

.banner-pagination .swiper-pagination-bullet {
  width: 30px;
  height: 3px;
  background-color: #998F63;
  margin: 0 2px !important;
  border-radius: 0;
  cursor: pointer;
  transition: background-color 0.3s;
}

.banner-pagination .swiper-pagination-bullet-active {
  background-color: #5C5C57;
}

.slick-dots.slick-thumb {
  display: flex;
  flex-direction: row;
  justify-content: center;
  @apply -bottom-6 lg:-bottom-8
}

.slick-dots.slick-thumb li {
  width: 6px;
  height: 6px;
  margin: 0 3px;
  border-radius: 999px;
  transition: all 0.5s ease-in-out;
  will-change: width;
  background-color:  #998F63;
}

.slick-dots.slick-thumb li.slick-active {
  background-color: #5C5C57;
  width: 24px;
}

.full-page-loader {
  @apply bg-black-multi;
  position: relative;
  width: 64px;
  height: 64px;
  transform: rotate(45deg);
  overflow: hidden;

  &::after {
    @apply bg-gray-multi;
    content: '';
    position: absolute;
    inset: 8px;
    margin: auto;
  }

  &::before {
    @apply bg-yellow-multi;
    content: '';
    position: absolute;
    inset: -15px;
    margin: auto;
    animation: diamondLoader 1.5s linear infinite;
  }

  @keyframes diamondLoader {
    0%  ,10% {
      transform: translate(-64px , -64px) rotate(-45deg)
    }
    90% , 100% {
      transform: translate(0px , 0px) rotate(-45deg)
    }
  }
}
